import * as React from 'react';
import styled from 'styled-components';

const PrimaryButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop};`}

  button {
    height: 40px;
    border-radius: 100px;
    padding: 8px;
    // margin: 0 10px;
  }
`;

export { PrimaryButtonContainer };
