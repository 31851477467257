const Url = require('url-parse');
import { applicationState } from 'data/state';

import {
  DEFAULT_LOGIN_REDIRECT_URL,
  PARTNER_LOGIN_REDIRECT_URL,
  WATR_LOGIN_REDIRECT_URL,
  ROOT_DOMAIN,
} from 'lib/constants';

function getLocationState(propLocation, field, defaultValue = null) {
  let value;
  if (propLocation.state) {
    value = propLocation.state[field];
  }
  return value || defaultValue;
}


function redirectToNextUrl(nextUrl) {
  // parse url for the hostname
  const url = new Url(nextUrl);

  if (!url.hostname.endsWith(ROOT_DOMAIN) && !url.hostname.endsWith('force.com')
  ) {
    alert('Invalid next argument')
    return;
  }
  window.location.assign(nextUrl);
}

function redirectToMainApp(subUrl='') {
  // add next behavior later..
  let url = DEFAULT_LOGIN_REDIRECT_URL;

  if (subUrl) {
    if (!subUrl.startsWith('/')) {
      subUrl = '/' + subUrl;
    }
    url += subUrl;
  }

  window.location.assign(url);
}

export { getLocationState, redirectToMainApp, redirectToNextUrl };
