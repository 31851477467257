import { colors } from './colors';

// const theme = {
//   colors: {
//     red: '#DB4254',
//     darkblue: '#1E5CB3',
//     greyblue: '#8B8EA5',
//     darkgrey: '#646464',
//     lightgrey: '#AAAAAA',
//     error: '#EB5757',
//   }

//   button: {
//     colors: {
//       disabled: '#d7d7d7',
//       primary: {
//         default: '#db4254',
//         light: '#ff586c',
//         dark: '#c02a3a',
//       },
//       secondary: {
//         default: '#212959',
//         light: '#414d96',
//         dark: '#161c3e',
//       },
//     },
//   },
// };


export const theme = {
  colors,
  global: {
  },
  header: {
    h1: {
      fontSize: '40px',
      lineHeight: '48px',
    },
    h2: {
      fontSize: '30px',
      lineHeight: '36px',
    },
    h3: {
      fontSize: '24px',
      lineHeight: '36px',
    },
  },
  text: {
    accent: colors.red.default,
  },
  button: {
    colors: {
      disabled: colors.neutral.light,
      primary: {
        default: colors.red.default,
        light: colors.red.light,
        dark: colors.red.dark,
      },
      secondary: {
        default: colors.blue.default,
        light: colors.blue.light,
        dark: colors.blue.dark,
      },
      simple: {
        default: colors.blue.default,
        light: colors.blue.light,
        dark: colors.blue.dark,
      },
    },
  },
  notice: {
    background: {
      color: '#FFFBEC',
    },
    label: {
      color: colors.neutral.darker,
    },
    description: {
      color: colors.neutral.darker,
    },
  },
  input: {
    label: {
      default: colors.neutral.dark,
    },
    border: {
      default: colors.neutral.lighter,
      dark: colors.neutral.dark,
    },
    borderRadius: '1px',
    selectButton: {
      light: 'transparent',
    },
    selectIcon: {
      default: colors.neutral.lighter,
      dark: colors.neutral.dark,
    },
  },
  docUpload: {
    notes: {
      default: colors.neutral.default,
    },
    dropzone: {
      background: {
        default: '#F6F6F6',
      },
      border: {
        default: colors.blue.darker,
      },
      action: {
        default: colors.red.default,
        dark: colors.red.dark,
      },
    },
  },
  infoBox: {
    border: {
      default: colors.neutral.lighter
    },
    title: {
      color: colors.blue.default,
    },
    label: {
      color: colors.neutral.dark,
    },
    item: {
      color: colors.blue.default,
      border: {
        default: colors.neutral.lighter,
      },
    },
  },
  // header: {
  //   h1: {
  //     fontSize: '40px',
  //     lineHeight: '48px',
  //   },
  //   h2: {
  //     fontSize: '30px',
  //     lineHeight: '36px',
  //   },
  //   h3: {
  //     fontSize: '24px',
  //     lineHeight: '36px',
  //   },
  // },
};
