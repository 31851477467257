import React from 'react';

import styled from 'styled-components';

const ContentSection = styled.div`
  grid-area: content;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 64px - 84px);
  background: #fff;
`;

const ContentInner = styled.div`
  ${(props) => props.centered && `text-align: center;`}
`;

function Content(props) {
  /*
  experimenting with centered arg, which makes the inner div ContentInner "text-align: center"
  */
  const {children, centered, ...otherProps} = props;

  return (
    <ContentWrapper>
      <ContentInner centered={centered} {...otherProps} >
        {props.children}
      </ContentInner>
    </ContentWrapper>
  );
}

export { ContentSection, Content, ContentWrapper, ContentInner };
