import { lazy } from 'react'
import { MFAVerify, MFASetup } from 'components/MFA';
// const { MFAVerify, MFASetup } = lazy(() => import('components/MFA'));
const Login = lazy(() => import('components/Login'));
const Logout = lazy(() => import('components/Logout'));
const PasswordReset = lazy(() => import('components/PasswordReset'));
const EmailVerification = lazy(() => import('components/EmailVerification'));
const Home = lazy(() => import('components/Home'));
const MFAReset = lazy(() => import('components/MFAReset/index'));

const routes = [
  {
    component: Login,
    exact: true,
    path: '/login',
  },
  {
    component: MFAVerify,
    exact: true,
    path: '/login/mfa-verify',
  },
  {
    component: Logout,
    exact: true,
    path: '/logout',
  },
  {
    component: MFASetup,
    exact: true,
    path: '/mfa-setup',
  },
  {
    component: MFAReset,
    path: '/mfa/reset',
  },
  {
    component: PasswordReset,
    path: '/password/reset',
  },
  {
    component: EmailVerification,
    path: '/email/verify',
  },
  {
    component: Home,
    path: '/home',
  },
];

export default routes;
