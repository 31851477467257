import React from "react";
import styled from "styled-components";
import { DEFAULT_LOGIN_REDIRECT_URL } from "lib/constants";
import logoImgUrl from "images/tusd-logo-app.svg";

let TopBarContainer = styled.div`
  grid-area: header;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  color: rgba(0, 0, 0, 0.80);
  font-size: 16px;
  font-weight: 500;
  line-height: 40px
  height: 64px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  box-shadow: 0 4px 16px 0 rgba(0,0,0,.04);
`;

let TopBarLogoLink = styled.a`
  display: flex;
  height: 40px;
  color: #000;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  gap: 16px;

  span {
    color: #000;
    font-size: 24px;
    font-weight: 600;
    line-height: 40px;
  }
`;

let TopBarLogo = styled.img`
  width: 40px;
  height: 40px;
`;
function TopBar() {
  let logoUrl = logoImgUrl;
  let loginRedirectUrl = DEFAULT_LOGIN_REDIRECT_URL;

  return (
    <TopBarContainer>
      <TopBarLogoLink href={loginRedirectUrl}>
        <TopBarLogo src={logoUrl} />
        <span>TrueUSD</span>
      </TopBarLogoLink>
    </TopBarContainer>
  );
}

export { TopBar };
