import * as React from "react";
import styled from "styled-components";
// import { FloatingShareButtons } from 'components/FloatingShareButtons';
import twitter from "images/twitter-x-fill.svg";
import discord from "images/discord-fill.svg";
import medium from "images/medium-fill.svg";
import telegram from "images/telegram-fill.svg";
import facebook from "images/facebook-circle-fill.svg";
import { Tooltip, Space, Flex } from 'antd';

let Center = styled.div`
  grid-area: footer;
  background: #fff;
  display: flex;
  justify-content: center;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 84px;
`;

const CenterBoxer = styled.div`
  width: 1200px;
  padding: 30px 40px;
  display: flex;
  justify-content: space-between;
`;

const Link = styled.a`
  text-decoration: none;
  color: rgba(0, 0, 0, 0.85);
  margin-right: 32px;
  &:last-child {
    margin-right: 0;
  }
  ${({ marginRight }) => marginRight && `margin-right: ${marginRight};`}
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  &:hover {
    color: #1a5aff;
  }
`;
const IconBoxer = styled.div`
  display: flex;
  gap: 24px;
`;
const MediaLink = styled.a`
  text-decoration: none;
  display: block;
  color: rgba(0, 0, 0, 0.85);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 40px;
  &:hover {
    color: #1A5AFF;
  }
`;

const IconMedia = styled.img`
  width: 24px;
  height: 24px;
  display: inline-block;
`;

const title = (
  <Space direction="vertical" size={6}>
      <Flex gap={12} align='center'>
      <IconMedia src={telegram} />
        <MediaLink
        href="https://t.me/TUSDofficialChannel"
        target="_blank"
        >TrueUSD Channel</MediaLink>
      </Flex>
      <Flex gap={12} align='center'>
      <IconMedia src={telegram} />
        <MediaLink
        href="https://t.me/TUSDofficial_EN"
        target="_blank"
        >TrueUSD Group</MediaLink>
      </Flex>
      <Flex gap={12} align='center'>
      <IconMedia src={telegram} />
        <MediaLink
        href="https://t.me/TUSDofficialCN"
        target="_blank"
        >TrueUSD Chinese Channel</MediaLink>
      </Flex>
    </Space>
)

export function Footer() {
  return (
    <Center>
      <CenterBoxer>
        <IconBoxer>
        <MediaLink
        href="https://twitter.com/tusdio"
        target="_blank"
      >
        <IconMedia src={twitter} />
      </MediaLink>
      <MediaLink
        href="https://discord.gg/DU4CXtcsSZ"
        target="_blank"
      >
        <IconMedia src={discord} />
      </MediaLink>
      <MediaLink
        href="https://medium.com/@trueusd"
        target="_blank"
      >
        <IconMedia src={medium} />
      </MediaLink>
      <Tooltip
      overlayStyle={{ width: '280px' }}
      color="#fff"
      title={title}
      placement="top"
      >
        <IconMedia src={telegram} />
      </Tooltip>
        </IconBoxer>
        <div>
          <Link href="https://app.tusd.io/terms-of-use" target="_blank">
            Terms of Use
          </Link>
          <Link href="https://app.tusd.io/privacy-policy" target="_blank">
            Privacy Policy
          </Link>
          <Link href="https://trueusd.zendesk.com" target="_blank">
            Help Center?
          </Link>
        </div>
      </CenterBoxer>
    </Center>
  );
}
