import React from "react";
import Lottie from "lottie-react";
import styled from "styled-components";
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 64px - 84px);
  background: #fff;
  justify-content: center;
`;
const Loading = () => {
  return (
    <ContentWrapper>
      <Lottie
        animationData={require("json/lottiefiles-loading.json")}
        loop
        autoplay
        style={{ height: "100px" }}
      />
    </ContentWrapper>
  );
};

export default Loading;
