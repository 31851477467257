import React from 'react';

import { Content, Banner } from 'components/layout';
import { H1, Explanation } from 'components/common/Header';

function PageNotFound(props) {
  return (
    <Content centered>
      <Banner title="Page not found" desc="You have reached a page that does not exist.">
        Not Found!
      </Banner>
      {/* <H1> Page not found</H1>
      <Explanation>
        You have reached a page that does not exist.
      </Explanation> */}
    </Content>
  );
}

export { PageNotFound };
