import React from "react";
import styled from "styled-components";
import queryString from "query-string";
import { PrimaryButton } from "components/common/PrimaryButton";
import { PrimaryButtonContainer } from "components/common/PrimaryButtonContainer";
import { PrimaryForm } from "components/form/PrimaryForm";
import { Content, Banner } from "components/layout";
import {
  MFACodeFieldWithError,
  mfaCodeValidationSchema,
} from "components/MFA/common";
import { hasErrors } from "lib/form";
import { apiClient, parseAPIError } from "lib/api";
import {
  getLocationState,
  redirectToMainApp,
  redirectToNextUrl,
} from "lib/routing";
import { Button, Form, Input, message } from "antd";

const ResetLinkBoxer = styled.div`
  margin-top: 22px;
`;
const ResetMFALink = styled.a`
  color: #1a5aff;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  padding-left: 4px;
  &:hover {
    color: #427eff;
  }
`;

function MFAVerifyFormComponent(props) {
  const { values, errors, isSubmitting } = props;

  const submitEnabled = values.mfaCode && !hasErrors(errors) && !isSubmitting;

  return (
    <PrimaryForm>
      <MFACodeFieldWithError name="mfaCode" autoFocus />
      <PrimaryButton type="submit" disabled={!submitEnabled}>
        {" "}
        Continue{" "}
      </PrimaryButton>
    </PrimaryForm>
  );
}

const MFA_VERIFY_URL = "/login/verify_otp";
const MFA_VERIFY_TT_URL = "/login/verify_tt_otp";

function verifyMFA(stateToken, otpToken) {
  return apiClient.post(MFA_VERIFY_URL, {
    state_token: stateToken,
    otp_token: otpToken,
  });
}

function verifyMFATT(stateToken, otpToken) {
  return apiClient.post(MFA_VERIFY_TT_URL, {
    state_token: stateToken,
    otp_token: otpToken,
  });
}

function MFAVerify(props) {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const queryArgs = queryString.parse(props.location.search);
  const stateToken = getLocationState(
    props.location,
    "stateToken",
    queryArgs.state_token
  );
  const isTrustTokenUser = queryArgs.user ? 0 : 1;
  // @ts-ignore
  const nextUrl = queryArgs.next && queryArgs.next.replace("-", "="); // to allow nextUrls with query parameters

  const submitMFAVerification = (values, actions) => {
    setIsSubmitting(true);
    if (isTrustTokenUser === 1) {
      verifyMFA(stateToken, values.mfaCode)
        .then((resp) => {
          // success, go to main app or redirect url
          if (nextUrl) {
            redirectToNextUrl(nextUrl);
          } else {
            redirectToMainApp();
          }
        })
        .catch((error) => {
          const errorInfo = parseAPIError(error);
          const errorMsg = errorInfo.message || "Wrong MFA code";
          // actions.setErrors({mfaCode: errorMsg});
          message.error(errorMsg);
          if (errorMsg === 'Your session has timed out, Please login again.') {
            props.history.push({
              pathname: "/login",
            });
          }
        })
        .finally(() => {
          setIsSubmitting(false);
          // actions.setSubmitting(false);
        });
    } else {
      verifyMFATT(stateToken, values.mfaCode)
        .then((resp) => {
          // success, go to main app or redirect url
          if (nextUrl) {
            redirectToNextUrl(nextUrl);
          } else {
            redirectToNextUrl(
              `/mfa-setup?login_state_token=${resp.data.state_token}&mfaSetupData=${resp.data.otp_device}`
            );
          }
        })
        .catch((error) => {
          const errorInfo = parseAPIError(error);
          const errorMsg = errorInfo.message || "Wrong MFA code";
          // actions.setErrors({mfaCode: errorMsg});
          message.error(errorMsg);
          if (errorMsg === 'Your session has timed out, Please login again.') {
            props.history.push({
              pathname: "/login",
            });
          }
        })
        .finally(() => {
          setIsSubmitting(false);
          // actions.setSubmitting(false);
        });
    }
  };

  return (
    // <Content centered>
    //   {(stateToken) ?
    //     <>
    //       <H1> Two-step verification</H1>
    //       {/* @ts-ignore */}
    //       <Explanation> Enter the <b>{isTrustTokenUser == 0 ? 'trusttoken.com' : 'tusd.io'}</b> verification code generated by your mobile application </Explanation>
    //       <ResetMFALink href="https://docs.google.com/forms/d/19BqUsniB8SCJGBPPU8qG83JyFhY9-QIMQTR-gerrLb8/edit" target="_blank">
    //         Have an issue with 2FA? Click here to reset
    //       </ResetMFALink>
    //       <Formik
    //          initialValues={{ mfaCode: '' }}
    //          onSubmit={submitMFAVerification}
    //          component={MFAVerifyFormComponent}
    //          validationSchema={mfaCodeValidationSchema}
    //       />
    //     </> :
    //     <Explanation> Error, please go back and retry </Explanation>
    //   }
    // </Content>
    <Content>
      {/* @ts-ignore */}
      <Banner
        title="Two-step verification"
        desc={`Enter the ${
          isTrustTokenUser == 0 ? "trusttoken.com" : "tusd.io"
        } verification code generated by your mobile application`}
      >
        <Form
          layout="vertical"
          style={{
            width: 384,
          }}
          initialValues={{
            mfaCode: "",
          }}
          variant="filled"
          requiredMark={false}
          onFinish={submitMFAVerification}
        >
          <Form.Item
            label="6-digit code"
            name="mfaCode"
            style={{paddingLeft: '32px'}}
            rules={[
              {
                required: true,
                message: "Please input your MFA Code",
              },
            ]}
          >
            <Input.OTP size="large" variant="filled" />
          </Form.Item>

          <Form.Item style={{
            marginTop: '16px',
            width: '320px'
          }}>
            <PrimaryButtonContainer>
              <Button
                block
                type="primary"
                htmlType="submit"
                loading={isSubmitting}
              >
                Continue
              </Button>
            </PrimaryButtonContainer>
            <ResetLinkBoxer>
              Have an issue with 2FA?
              <ResetMFALink
                href="https://docs.google.com/forms/d/19BqUsniB8SCJGBPPU8qG83JyFhY9-QIMQTR-gerrLb8/edit"
                target="_blank"
              >
                Reset
              </ResetMFALink>
            </ResetLinkBoxer>
          </Form.Item>
        </Form>
      </Banner>
    </Content>
  );
}

export { MFAVerify };
