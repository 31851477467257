import React from 'react';
import * as ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import Root from 'components/Root';
import  { theme } from 'styles/theme';
import createBrowserHistory from 'history/createBrowserHistory';
import { registerUnauthorizedInterceptor } from 'lib/api';

const history = createBrowserHistory();

let themeToUse = theme;

registerUnauthorizedInterceptor(history);

ReactDOM.render(
  <ThemeProvider theme={themeToUse}>
    <Router history={history}>
      <Root />
    </Router>
  </ThemeProvider>,
  document.getElementById('root')
);
