const ROOT_DOMAIN = process.env.REACT_APP_ROOT_DOMAIN || 'tusd.io';
const DEFAULT_LOGIN_REDIRECT_URL = process.env.REACT_APP_DEFAULT_LOGIN_REDIRECT_URL || 'https://app.tusd.io';
const PARTNER_LOGIN_REDIRECT_URL = process.env.REACT_APP_PARTNER_LOGIN_REDIRECT_URL || 'https://partner.tusd.io';

const WATR_ROOT_DOMAIN = 'watrid.com';
const WATR_LOGIN_REDIRECT_URL = process.env.REACT_APP_WATR_LOGIN_REDIRECT_URL || 'https://app.watrid.com';

export {
  ROOT_DOMAIN,
  DEFAULT_LOGIN_REDIRECT_URL,
  PARTNER_LOGIN_REDIRECT_URL,
  WATR_ROOT_DOMAIN,
  WATR_LOGIN_REDIRECT_URL,
};
