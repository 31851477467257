import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import styled from 'styled-components';
import { PrimaryButton } from 'components/common/PrimaryButton';
import { PrimaryForm } from 'components/form/PrimaryForm';
import { Content, Banner } from 'components/layout';
import { MFACodeFieldWithError, mfaCodeValidationSchema } from 'components/MFA/common';
import { getUserMFASetupInfo } from 'components/MFA/api';
import { apiClient } from 'lib/api';
import { getLocationState } from 'lib/routing';
import { redirectToMainApp } from 'lib/routing';
import queryString from 'query-string';
import { Button, Form, Input, message, Typography  } from "antd";
import { PrimaryButtonContainer } from 'components/common/PrimaryButtonContainer';

const MFABoxer = styled.div`
display: flex;
flex-direction: column;
gap: 24px;
align-items: center;
`
const CardBoxer = styled.div`
border-radius: 8px;
border: 1px solid rgba(0, 0, 0, 0.10);
background: #FFF;
padding: 24px 32px 0;
width: 384px;
`

const Title = styled.div`
color: rgba(0, 0, 0, 0.85);
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 22px; /* 137.5% */
text-align: left;
`
const QRInstructionsWrapper = styled.div`
  text-align: center;
`;

const HelpLink = styled.a`
  font-size: 14px;
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.65);
  &:hover {
    color: #1A5AFF;
  }
`;

const QRInstructions = styled.div`
  text-align: left;
  padding: 8px 0 24px;
  color: rgba(0, 0, 0, 0.65);
font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 22px;
`;

const QRCodeWrapper = styled.div`
  text-align: center;
`;

const SubmitButton = styled(PrimaryButton)`
  margin-top: 40px;
`;

const MFASetupForm = styled(PrimaryForm)`
  margin-top: 10px;
`;

function MFASetupFormComponent(props) {
  const {values, errors, isSubmitting} = props;

  const submitEnabled = values.mfaCode && !isSubmitting;

  return (
    <MFASetupForm>
      <MFACodeFieldWithError name="mfaCode"/>
        <div style={{ marginTop: '10px' }}>
          <HelpLink href="https://trueusd.zendesk.com/hc/en-us/articles/4408366047641-What-is-2-Factor-Authentication-2FA-" target="_blank">
            Need help setting the 2FA?
          </HelpLink>
        </div>
      <SubmitButton type="submit" disabled={!submitEnabled}> Continue </SubmitButton>
    </MFASetupForm>
  );
}

const MFA_CONFIRM_BASE_URL = '/users/me/otp_devices';

function confirmMFADevice(otpDeviceId, code, loginStateToken=null) {
  const url = `${MFA_CONFIRM_BASE_URL}/${otpDeviceId}/confirm`;
  const data: any = {token: code};
  if (loginStateToken) {
    data.login_state_token = loginStateToken;
  }
  return apiClient.post(url, data);
}

function MFASetup(props) {
  const queryArgs = queryString.parse(props.location.search);

  // get MFA data from previous create account page's API call
  const initialMFASetupData = getLocationState(props.location, 'mfaSetupData');
  const loginStateToken = getLocationState(props.location, 'loginStateToken', queryArgs.login_state_token);

  const [mfaSetupData, setMfaSetupData] = useState(initialMFASetupData);

  useEffect(() => {
    // if not preloaded, refetch it
    if (!mfaSetupData) {
      const data: any = {};
      if (loginStateToken) {
        data.login_state_token = loginStateToken;
      }

      getUserMFASetupInfo(data).then((res) => {
        const mfaSetupData = res.data;
        setMfaSetupData(mfaSetupData);
      }).catch((error) => {
        let errorMsg = 'Problem creating account';
        if (error.response && error.response.data) {
          errorMsg = error.response.data.message;
        }
        message.error(errorMsg);
        if (errorMsg === 'Your session has timed out, Please login again.') {
          props.history.push({
            pathname: "/login",
          });
        }
      });
    }
  }, []);

  let otpDevice;
  let secretKey;
  if (mfaSetupData) {
    otpDevice = mfaSetupData.otp_device;
    secretKey = queryString.parseUrl(otpDevice?.uri)?.query?.secret || '';
  }

  const mfaAlreadySetup = (mfaSetupData && otpDevice === null);

  const submitMFAConfirmation = (values, actions) => {
    confirmMFADevice(otpDevice.id, values.mfaCode, loginStateToken)
      .then((resp) => {
        redirectToMainApp();
      })
      .catch((error) => {
        let errorMsg = 'Problem creating account';
        if (error.response && error.response.data) {
          errorMsg = error.response.data.message;
        }
        // actions.setErrors({mfaCode: errorMsg});
        message.error(errorMsg);
        if (errorMsg === 'Your session has timed out, Please login again.') {
          props.history.push({
            pathname: "/login",
          });
        }
      })
      // .then(() => {
      //   actions.setSubmitting(false);
      // });
  };

  return (
    <Content centered>
      {/* <H1>Two-factor authentication (2FA)</H1>
      {mfaAlreadySetup && <QRInstructionsWrapper> Multi-factor authentication device is already setup </QRInstructionsWrapper>}

      {otpDevice &&
        <>
          <QRInstructionsWrapper>
            <QRInstructions>
              Scan the QR code with an authenticator app.
            </QRInstructions>
            <QRCodeWrapper>
              <QRCode value={otpDevice.uri} size={240}/>
            </QRCodeWrapper>
            <QRInstructions>
              Then enter the 6-digit code generated by your authenticator app.
            </QRInstructions>
          </QRInstructionsWrapper>

          <Formik
             initialValues={{ mfaCode: '' }}
             onSubmit={submitMFAConfirmation}
             component={MFASetupFormComponent}
             validationSchema={mfaCodeValidationSchema}
          />
        </>
      } */}

      {/*<BottomLink to="/">Need an authenticator app? </BottomLink>*/}
      <Banner title="Two-factor authentication (2FA)">
      {mfaAlreadySetup && <QRInstructionsWrapper> Multi-factor authentication device is already setup </QRInstructionsWrapper>}
      {otpDevice &&
        <MFABoxer>
          <CardBoxer>
            <Title>Step.1</Title>
          <QRInstructionsWrapper>
            <QRInstructions>
              Scan the QR code with an authenticator app.
            </QRInstructions>
            <QRCodeWrapper>
              <QRCode value={otpDevice.uri} size={100}/>
            </QRCodeWrapper>
            <Typography.Text copyable>{secretKey}</Typography.Text>
            <QRInstructions>
            </QRInstructions>
          </QRInstructionsWrapper>
          </CardBoxer>
          <CardBoxer>
          <Title>Step.2</Title>
          <QRInstructions>Then enter the 6-digit code generated by your authenticator app.</QRInstructions>
          <Form
          layout="vertical"
          style={{
            width: 320,
          }}
          initialValues={{
            mfaCode: "",
          }}
          requiredMark={false}
          onFinish={submitMFAConfirmation}
        >
          <Form.Item
            label="6-digit code"
            name="mfaCode"
            style={{
              width: 320,
            }}
            rules={[
              {
                required: true,
                message: "Please input your MFA Code",
              },
            ]}
          >
            <Input.OTP size="large" variant="filled" />
          </Form.Item>

          <Form.Item
          style={{
            width: 320,
          }}
          >
            <PrimaryButtonContainer>
            <Button
              block
              type="primary"
              htmlType="submit"
            >
              Continue
            </Button>
            </PrimaryButtonContainer>
            <div style={{ marginTop: '10px' }}>
          <HelpLink href="https://trueusd.zendesk.com/hc/en-us/articles/4408366047641-What-is-2-Factor-Authentication-2FA-" target="_blank">
            Need help setting the 2FA?
          </HelpLink>
        </div>
          </Form.Item>
        </Form>
        </CardBoxer>
        </MFABoxer>
      }
      </Banner>
    </Content>
  );
}

export { MFASetup , MFASetupForm, QRInstructionsWrapper, QRInstructions, QRCodeWrapper, SubmitButton };
