import { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';
// import { theme } from './theme';

// console.log(theme);

// const specialForAuthWeb = `
//   * {
//     box-sizing: border-box;
//   }

//   html {
//     font-size: initial;
//   }

//   a {
//     text-decoration: none;
//     color: ${theme.colors.darkblue};
//   }
// `;

// Stolen from `trusttoken-web/packages/truecurrency/src/lib/trusttoken-ui/styles/GlobalStyles.ts`
export const GlobalStyles = createGlobalStyle`
  ${styledNormalize}

  html {
    box-sizing: border-box;
    font-size: 10px;
    height: 100%;
    background: ${({ theme }) => theme.colors.background};
  }

  body {
    font-family: '${({ theme }) => theme.global.fontFamily}', sans-serif;
    height: 100%;
  }

  #root {
    height: 100%;
  }

  .ReactModal__Body--open {
    overflow-y: hidden;
  }

  h1, h2, h3, h4 {
    font-family: '${({ theme }) => theme.global.fontFamily}', sans-serif;
    color: black;
  }

  h1 {
    font-size: 38px;
    line-height: 1.2;
  }

  h2 {
    font-size: 30px;
    line-height: 1.2;
  }

  h3 {
    font-size: 24px;
    line-height: 1.25;
  }

  h4 {
    font-size: 20px;
    line-height: normal;
  }

  p {
    font-size: 16px;
    line-height: 1.5;
  }

  * {
    box-sizing: border-box;
  }

  html {
    font-size: initial;
  }

  a {
    text-decoration: none;
    color: #1E5CB3;
  }
`;
