import React from "react";
import styled from "styled-components";
import banner from "images/top-bg.svg";

const BannerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background: #111;
`;

const BannerInner = styled.div`
  width: 1440px;
  height: 264px;
  background-image: url("${banner}");
  background-repeat: no-repeat, no-repeat;
  display: flex;
  justify-content: center;
`;

const TitleBoxer = styled.div`
  height: 208px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const Title = styled.div`
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
`;

const Desc = styled.div`
  color: rgba(255, 255, 255, 0.85);
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
`;
const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => props.empty ? '-168px' : '-59px'};
  padding-bottom: 100px;
`;
const Content = styled.div`
  width: 1200px;
  padding: 64px 0;
  flex-shrink: 0;
  border-radius: 48px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function Banner(props) {
  const { children, title, desc, empty } = props;
  return (
    <div>
      <BannerWrapper>
        <BannerInner>
          {(title || desc) && (
            <TitleBoxer>
                <Title>{title}</Title>
                {desc && <Desc>{desc}</Desc>}
            </TitleBoxer>
          )}
        </BannerInner>
      </BannerWrapper>
      <ContentWrapper empty={empty}>
        <Content>{children}</Content>
      </ContentWrapper>
    </div>
  );
}

export { Banner };
