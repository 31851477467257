import React from "react";
import { Helmet } from "react-helmet";
import { Route, Switch, Redirect, Link } from "react-router-dom";
import { GlobalStyles } from "styles/GlobalStyles";
import { Page, TopBar, ContentSection, Footer } from "components/layout";
import { PageNotFound } from "components/PageNotFound";
import routes from "data/routes";
import { isDeployedEnv } from "lib/env";
import faviconUrl from "images/tusd-logo-app.svg";
import { ConfigProvider, App } from "antd";
import { colors } from "styles/antdcolor";
import "styles/antd.scss";
import { Suspense } from "react";
import Loading from "./Loading";

const Root = () => {
  let title = "TrueUSD";
  let favicon = faviconUrl;

  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
        {isDeployedEnv() && (
          <script
            id="ze-snippet"
            src="https://static.zdassets.com/ekr/snippet.js?key=b5d6bffa-aaa2-4974-bdbd-593b23ec387b"
          >
            {" "}
          </script>
        )}
      </Helmet>
      <GlobalStyles />
      <ConfigProvider
        theme={{
          token: colors,
        }}
      >
        <App>
          <Page>
            <TopBar />
            <Suspense fallback={<Loading />}>
              <ContentSection>
                <Switch>
                  {routes.map((route) => (
                    <Route key={route.path} {...route} />
                  ))}
                  <Redirect exact from="/" to="/login" />
                  <Route component={PageNotFound} />
                </Switch>
              </ContentSection>
            </Suspense>
            <Footer />
          </Page>
        </App>
      </ConfigProvider>
    </React.Fragment>
  );
};

export default Root;
