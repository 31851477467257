import styled from "styled-components";

export const Page = styled.div`
  display: grid;
  min-height: 100%;
  grid-template-rows: 150px 1fr 50px;
  grid-template-areas:
    "header"
    "content"
    "footer";

  @media (min-width: 600px) {
    grid-template-rows: 64px 1fr 84px;
    grid-template-areas:
      "header"
      "content"
      "footer";
  }
`;
